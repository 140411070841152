import {
  UserAccountInfoResponseType,
  UserAccountItem,
} from '@type/api/base/user';
import { AddressDataType } from '@type/api/main/address';
import { CvsShopsData } from '@type/api/order/ordersPrepared';
import {
  InterfaceProductDetailDataType,
  OpenGalleryParams,
} from '@type/webView/interface';
import { MyLocationInterfaceItemType } from '@type/webView/myLocation';
import { SharedLocationType, StoreDetailType } from '@type/webView/store';
import { LocationInterfaceItemType } from '@type/webView/town';

import isLatestVersionMoreThan from './isAppVersionOf';
import { getValidResourceUrl } from './lib';
import {
  isAndroidApp,
  isIOSApp,
  getAppDataByStorage,
  isFlutterApp,
  isAppDevice,
  isMainWeb,
} from './webview';

const aosInterface = window.qdillion;
const iosInterface = window.webkit && window.webkit.messageHandlers;
const flutterInterface = window.flutter_inappwebview;

/** 앱 정보 요청 (토큰정보 포함) */
export const initGetAppInfo = (): boolean => {
  if (isAndroidApp() && aosInterface) {
    aosInterface.getAppInfo();
    return true;
  }
  if (isIOSApp() && !flutterInterface) {
    iosInterface.getAppInfo.postMessage('');
    return true;
  }

  if (flutterInterface) {
    flutterInterface.callHandler('getAppInfo', '');
    return true;
  }

  return false;
};

export const setMaxBrightness = (): void => {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    return aosInterface?.setMaxBrightness();
  }

  if (isIOSApp() && !flutterInterface) {
    return iosInterface?.setMaxBrightness?.postMessage();
  }

  return window?.flutter_inappwebview?.callHandler('setMaxBrightness', '');
};

export const resetBrightness = (): void => {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    return aosInterface?.resetBrightness();
  }

  if (isIOSApp() && !flutterInterface) {
    return iosInterface?.resetBrightness?.postMessage();
  }

  return window?.flutter_inappwebview?.callHandler('resetBrightness', '');
};

export const openPickupBottomSheet = (shopInfo: CvsShopsData): void => {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    return aosInterface?.openPickupBottomSheet(JSON.stringify(shopInfo));
  }

  if (isIOSApp() && !flutterInterface) {
    return iosInterface?.openPickupBottomSheet?.postMessage(
      JSON.stringify(shopInfo),
    );
  }

  return window?.flutter_inappwebview?.callHandler(
    'openPickupBottomSheet',
    JSON.stringify(shopInfo),
  );
};

export const setLightStatusBar = (): void => {
  if (isAndroidApp() && aosInterface) aosInterface.lightStatusbar();
  if (isIOSApp() && !flutterInterface)
    iosInterface.lightStatusbar.postMessage('');
  return window?.flutter_inappwebview?.callHandler('lightStatusbar', '');
};

export const setDarkStatusBar = (): void => {
  if (isAndroidApp() && aosInterface) aosInterface.darkStatusbar();
  if (isIOSApp() && !flutterInterface)
    iosInterface.darkStatusbar.postMessage('');
  return window?.flutter_inappwebview?.callHandler('darkStatusbar', '');
};

/** 웹 뷰 닫기 */
export const closeWebView = (): void => {
  if (isMainWeb()) {
    window.parent.postMessage('close', '*');
    return;
  }

  if (isAndroidApp() && aosInterface) {
    aosInterface.close();
    return;
  }
  if (isIOSApp() && !flutterInterface) {
    iosInterface.closeEnd.postMessage('');
    return;
  }
  window?.flutter_inappwebview?.callHandler('close', '');
};

// 웹뷰 닫기 (물리 백버튼) - true면 웹뷰닫기, false면 default
export function interfaceCloseWebviewHardwareBackKey(bool: boolean): void {
  if (isFlutterApp()) {
    flutterInterface?.callHandler(
      'cancelPaymentWithHardwareBackKey',
      JSON.stringify(bool),
    );
  }
}

/** 로그인 팝업 오픈 요청 */
export const showLoginPopup = (url = window.location.href): void => {
  if (isAndroidApp() && aosInterface) aosInterface.openLoginPopup(url);
  if (isIOSApp() && !flutterInterface)
    iosInterface.openLoginPopup.postMessage(url);
  return window?.flutter_inappwebview?.callHandler('openLoginPopup', url);
};

/* 회원가입 페이지로 연결 */
export const interfaceShowJoinPopup = () => {
  if (isFlutterApp()) {
    window?.flutter_inappwebview?.callHandler('showJoinPopup', '');
    return true;
  }

  if (isAndroidApp() && aosInterface) {
    aosInterface.showJoinPopup();
    return true;
  }
  if (isIOSApp() && !flutterInterface) {
    iosInterface.showJoinPopup.postMessage('');
    return true;
  }

  return false;
};

/** 카카오톡 공유하기 */
export const interfaceShareKakao = (content: any): void => {
  if (isFlutterApp()) {
    window?.flutter_inappwebview?.callHandler(
      'shareKakao',
      JSON.stringify(content),
    );
  } else {
    if (isAndroidApp() && aosInterface) {
      window.qdillion.shareKakao(JSON.stringify(content));
    }
    if (isIOSApp() && !flutterInterface) {
      window.webkit.messageHandlers.shareKakao.postMessage(
        JSON.stringify(content),
      );
    }
  }
};

/** 앱 상품상세 open interface */
export const interfaceOpenAppProductDetail = (
  data: InterfaceProductDetailDataType,
) => {
  if (isFlutterApp()) {
    window?.flutter_inappwebview?.callHandler(
      'openProductDetail',
      JSON.stringify(data),
    );
  } else {
    if (isAndroidApp() && aosInterface) {
      window.qdillion.openProductDetail(JSON.stringify(data));
    }

    if (isIOSApp() && !flutterInterface) {
      window.webkit.messageHandlers.openProductDetail.postMessage(
        JSON.stringify(data),
      );
    }
  }
};

// 안드로이드 뒤로가기 작동 여부
export const interfaceAosBackHold = (isBack = false) => {
  if (isAndroidApp() && aosInterface && compareAppVersionIsLatest('7.0.7')) {
    aosInterface.setBackHold(isBack);
  } else if (isFlutterApp()) {
    window?.flutter_inappwebview?.callHandler('setBackHold', isBack);
  }
};

/** 앱 버전 체크
 * @param versionNum ex) 7.2.3
 */
export const compareAppVersionIsLatest = (versionNum: string) => {
  const data = getAppDataByStorage(true);

  // UserAgent가 없을 경우
  if (data === null) {
    return false;
  }

  const baseVersion = versionNum.split('.').map(Number);
  const nowVersion = data['App-Version'].split('.').map(Number);

  for (let i = 0; i < nowVersion.length; i += 1) {
    baseVersion[i] = baseVersion[i] || 0;
    if (nowVersion[i] > baseVersion[i]) {
      return true;
    }
    if (nowVersion[i] !== baseVersion[i]) {
      return false;
    }
  }
  return baseVersion.length <= nowVersion.length;
};

// 주소검색
export function setDelivery(address: Partial<AddressDataType>): void {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    return aosInterface?.setDelivery(JSON.stringify(address));
  }

  if (isIOSApp() && !flutterInterface) {
    return iosInterface?.setDelivery?.postMessage(JSON.stringify(address));
  }

  return window?.flutter_inappwebview?.callHandler(
    'setDelivery',
    JSON.stringify(address),
  );
}

// 앱 후기 유도 팝업
// FE-555 : 앱 버전 분기를 태워야하는 이슈가 있어 버전별 분기 태웠습니다. 강업시 분기 삭제 필요
export const appReviewPopup = (
  path: string = window.location.href,
  label: string,
  locationCode: string,
  isClose: boolean,
): void => {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    if (compareAppVersionIsLatest('7.0.8')) {
      aosInterface.ratingPopup(path, label, locationCode, isClose);
    } else if (compareAppVersionIsLatest('7.0.6')) {
      aosInterface.ratingPopup(path, label);
    } else {
      aosInterface.ratingPopup(path);
    }
  }

  if (isIOSApp() && !flutterInterface) {
    if (compareAppVersionIsLatest('4.4.9')) {
      const data = {
        path,
        label,
        locationCode,
      };
      iosInterface.ratingPopup.postMessage(JSON.stringify(data));
    } else if (compareAppVersionIsLatest('4.4.7')) {
      const data = {
        path,
        label,
      };
      iosInterface.ratingPopup.postMessage(JSON.stringify(data));
    } else {
      iosInterface.ratingPopup.postMessage(path);
    }
  }

  if (isFlutterApp()) {
    flutterInterface?.callHandler(
      'ratingPopup',
      JSON.stringify({ path, label, locationCode }),
    );
  }
};

export const interfaceProductRefresh = () => {
  if (isAndroidApp() && aosInterface) {
    return aosInterface.productRefresh();
  }
  if (isIOSApp() && !flutterInterface) {
    return iosInterface.productRefresh.postMessage('');
  }

  return flutterInterface?.callHandler('productRefresh', '');
};

// 네이티브 결제하기 네비게이션바 노출
export function interfacePaymentNavigation(
  data: Record<string, unknown>,
): boolean {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    aosInterface.showPaymentNavigation(JSON.stringify(data));
    return true;
  }

  if (isIOSApp() && !flutterInterface) {
    iosInterface.showPaymentNavigation.postMessage(JSON.stringify(data));
    return true;
  }

  if (isFlutterApp()) {
    flutterInterface.callHandler('showPaymentNavigation', JSON.stringify(data));
    return true;
  }

  return false;
}

/** 앱스플라이어 데이터 전송. CR-2009 */
export function interfaceSendAppsflyer(
  name: string,
  params: Record<string, unknown>,
): boolean {
  if (isAndroidApp() && aosInterface) {
    const data = {
      name,
      params: JSON.stringify(params),
    };

    aosInterface.sendAppsflyer(data.name, data.params);
    return true;
  }

  if (isIOSApp() && !flutterInterface) {
    const data = {
      name,
      params,
    };

    iosInterface.sendAppsflyer.postMessage(JSON.stringify(data));
    return true;
  }

  if (isFlutterApp()) {
    const data = {
      name,
      params,
    };

    flutterInterface.callHandler('sendAppsflyer', JSON.stringify(data));
    return true;
  }

  return false;
}

export function interfaceSendAirBridge(
  name: string,
  params: Record<string, unknown>,
): void {
  if (isFlutterApp()) {
    const data = {
      name,
      params,
    };
    flutterInterface.callHandler('sendAirbridge', JSON.stringify(data));
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function interfaceOnBuzzvilDailyCheck(buzzvilDailyCheckData: {
  title: string;
  body: string;
  hyperText: string;
  hyperLink: string;
}): void {
  if (isFlutterApp()) {
    flutterInterface.callHandler(
      'onBuzzvilDailyCheck',
      JSON.stringify(buzzvilDailyCheckData),
    );
  } else {
    if (isAndroidApp() && aosInterface) {
      window.qdillion.onBuzzvilDailyCheck(
        JSON.stringify(buzzvilDailyCheckData),
      );
    }

    if (isIOSApp() && !flutterInterface) {
      window.webkit.messageHandlers.onBuzzvilDailyCheck.postMessage(
        JSON.stringify(buzzvilDailyCheckData),
      );
    }
  }
}

// 내부 UserActionLog 적재용 인터페이스
export function interfaceSendGAUserActionLog(params: {
  [key: string]: any;
}): void {
  const data = {
    api: 'v25/search/user/ga/log',
    params,
  };

  if (isFlutterApp()) {
    flutterInterface.callHandler('userActionLog', JSON.stringify(data));
  }
}

// Google Analytics 적재용 인터페이스 (UA)
// export function interfaceSendGALog(params: { [key: string]: any }): void {
//   const data = {
//     params,
//   };

//   if (isFlutterApp()) {
//     flutterInterface.callHandler('sendGA', JSON.stringify(data));
//   }
// }

// Google Analytics 적재용 인터페이스 (GA4)
// TODO: UA 정리되면 interface 명 변경. (interfaceSendGA4 -> interfaceSendGALog)
export function interfaceSendGA4(params: { [key: string]: any }): void {
  const data = {
    params,
  };

  if (isFlutterApp()) {
    flutterInterface.callHandler('sendGA4', JSON.stringify(data));
  }
}

/**
 * @param name SHARE_CONTENT_DETAIL 콘텐츠 공유 / SHARE_EVENT_DETAIL 이벤트 공유 / SHARE_PRODUCT_DETAIL 상품 공유
 */
export function interfaceSharePopup(
  name:
    | 'SHARE_URL'
    | 'SHARE_CONTENT_DETAIL'
    | 'SHARE_EVENT_DETAIL'
    | 'SHARE_PRODUCT_DETAIL',
  params: {
    title: string;
    seq?: number;
    linkUrl?: string;
  },
): boolean {
  const data = {
    name,
    params,
  };

  const isWorking =
    isFlutterApp() &&
    isLatestVersionMoreThan({ iosVersion: '4.9.2', aosVersion: '7.5.6' });

  if (isWorking) {
    flutterInterface.callHandler('sharePopup', JSON.stringify(data));
  }

  return isWorking;
}

export function interfaceDirectTransactionStore(
  townLesult: LocationInterfaceItemType,
): void {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    window.qdillion.shareLocation(JSON.stringify(townLesult));
  }

  if (isIOSApp() && !flutterInterface) {
    window.webkit.messageHandlers.shareLocation.postMessage(
      JSON.stringify(townLesult),
    );
  }

  if (isFlutterApp()) {
    flutterInterface.callHandler('setLocation', JSON.stringify(townLesult));
  }
}

export function interfaceShareStore(location: StoreDetailType): void {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    window.qdillion.shareLocation(JSON.stringify(location));
  }

  if (isIOSApp() && !flutterInterface) {
    window.webkit.messageHandlers.shareLocation.postMessage(
      JSON.stringify(location),
    );
  }

  if (isFlutterApp()) {
    flutterInterface.callHandler('shareLocation', JSON.stringify(location));
  }
}

export function interfaceShareLocation(location: SharedLocationType): void {
  if (isAndroidApp() && aosInterface && !flutterInterface) {
    window.qdillion.shareLocation(JSON.stringify(location));
  }

  if (isIOSApp() && !flutterInterface) {
    window.webkit.messageHandlers.shareLocation.postMessage(
      JSON.stringify(location),
    );
  }

  if (isFlutterApp()) {
    flutterInterface.callHandler('shareLocation', JSON.stringify(location));
  }
}

export function interfaceGetScreenSize(): void {
  if (isFlutterApp()) {
    flutterInterface?.callHandler('getScreenSize');
  }
}

export function interfaceGetVirtualKeyboardStatus(): void {
  if (isFlutterApp()) {
    flutterInterface?.callHandler('getVirtualKeyboardEvent');
  }
}

export function interfaceGetCurrentLocation(): void {
  if (isFlutterApp()) {
    flutterInterface.callHandler('getCurrentLocation');
  }
}

export function interfaceSetToastMessage(msg: string): void {
  if (
    isFlutterApp() &&
    isLatestVersionMoreThan({ iosVersion: '5.0.1', aosVersion: '7.6.9' })
  ) {
    flutterInterface.callHandler('setToastMessage', msg);
  }
}
// 우리동네 등록
export function interfaceSetMyLocation(
  locationParam: MyLocationInterfaceItemType,
): void {
  if (isFlutterApp()) {
    flutterInterface.callHandler('setLocation', JSON.stringify(locationParam));
  }
}

export const interfaceSendBankAccountMessage = (
  data: UserAccountItem,
): void => {
  if (isFlutterApp()) {
    flutterInterface.callHandler(
      'getSelectedAccountInfo',
      JSON.stringify(data),
    );
  }
};

export const downloadImage = (url: string): void => {
  if (isFlutterApp()) {
    flutterInterface.callHandler(
      'webviewMediaDownload',
      getValidResourceUrl(url),
    );
  }
};

export const openGallery = ({
  photoListCount = 0,
  isContainVideo = false,
  galleryType,
}: OpenGalleryParams): void => {
  if (isFlutterApp()) {
    flutterInterface.callHandler(
      'openGallery',
      JSON.stringify({ photoListCount, isContainVideo, galleryType }),
    );
  }
};

export const interfaceOpenDeepLink = (
  urlInfo: { webUrl: string } | { deepLinkUrl: string },
): boolean => {
  if (isFlutterApp() && flutterInterface) {
    flutterInterface.callHandler('openDeeplink', JSON.stringify(urlInfo));
    return true;
  }
  return false;
};
